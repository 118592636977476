<template>
  <div>
    <v-card>
      <v-row>
        <v-col xs="12" class="mb-5">
          <v-card-title>
            <h3 class="primary--text" style="width:100%">Sign in with Username</h3>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="username" label="Username"></v-text-field>
            <v-text-field v-model="password" label="Password" type="password"></v-text-field>
            <v-btn
              color="primary"
              @click="logInUnPw({ email: username, password: password })"
              :disabled="!(username && password)"
            >Sign in</v-btn>
            <br />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { authComputed } from '@state/helpers'
import appConfig from '@src/app.config'
export default {
  page: {
    title: 'Log in',
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  components: {},
  data: () => ({
    username: '',
    password: '',
  }),
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions('auth', ['logInUnPw']),
  },
}
</script>
<style scoped>
</style>

<style lang="scss" module>
@import '@design';
Layout {
  text-align: center;
}
</style>
